import styled from '@emotion/styled';
import { ReactComponent as SettingsIcon } from '../assets/images/settings-icon.svg'; // GalleryIcon 이미지 임포트
import { Link } from 'react-router-dom';

const Header = ({ onLogoClick }) => {
  return (
    <HeaderContainer>
      <LogoContainer>
        <StyledLink to='/' onClick={onLogoClick}>
          <LogoText>Jangsu AI</LogoText>
        </StyledLink>
      </LogoContainer>
      <SettingsLink to='/settings'>
        <IconWrapper>
          <SettingsIcon fill='lightgray' style={{ width: '25px', height: '25px' }} />
        </IconWrapper>
      </SettingsLink>
    </HeaderContainer>
  );
};

export default Header;

const HeaderContainer = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-shrink: 0;
  /* width: 100%; */
  height: 55px; /* 헤더 높이 고정 */
  padding: 5px 10px;
  border-bottom: 0.5px solid lightgray;
  background-color: white;
  z-index: 1002;
`;

const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  color: black;
  &:hover,
  &:focus,
  &:active {
    text-decoration: none;
    color: black;
  }
`;

const LogoText = styled.h1`
  font-size: 25px;
  font-weight: bold;
  margin: 0;
  white-space: nowrap;
`;

const SettingsLink = styled(Link)`
  padding-right: 5px;
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
`;
