/** @jsxImportSource @emotion/react */
import React, { useState } from 'react';
import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
import BackIcon from '../assets/images/back-icon.png';
import { loadTossPayments } from '@tosspayments/sdk';
import { useAuth } from '../contexts/AuthContext';
import LoginModal from '../components/LoginModal';
import { getCustomerName, generateUniqueOrderId } from '../utils';
import { Helmet } from 'react-helmet-async';
import MainCardImg from '../assets/images/card-img.jpg';
import MainCardImg2 from '../assets/images/card-img-2.jpg';
import ExamCardImg1 from '../assets/images/my_mother.jpg';
import ExamCardImg2 from '../assets/images/my_father.jpg';

const Detail = () => {
  const { user, loading } = useAuth();
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false); // 결제 처리 상태

  const handlePayment = async () => {
    if (isProcessing) return; // 이미 결제가 진행 중이면 추가 요청 방지
    if (!user) {
      setShowLoginModal(true);
      return;
    }

    const { email, displayName } = user;
    const clientKey = process.env.REACT_APP_TOSS_PAYMENTS_CLIENT_KEY; // Real 토스페이먼츠 클라이언트 키

    if (!clientKey) {
      console.error('토스페이먼츠 클라이언트 키가 정의되지 않았습니다.');
      return;
    }

    setIsProcessing(true); // 결제 시작

    try {
      const tossPayments = await loadTossPayments(clientKey);
      const orderId = generateUniqueOrderId(displayName);
      const customerName = getCustomerName(displayName, email);

      try {
        await tossPayments.requestPayment('카드', {
          orderId, // 주문 번호
          customerName, // 구매자명
          amount: 9900, // 결제액
          orderName: '영정사진 | 장수사진', // 구매상품
          successUrl: `${process.env.REACT_APP_MAIN_DOMAIN}/success`, // 결제 성공 시 리다이렉트할 URL
          failUrl: `${process.env.REACT_APP_MAIN_DOMAIN}/fail`, // 결제 실패 시 리다이렉트할 URL
        });
      } catch (error) {
        if (error.code === 'USER_CANCEL') {
          console.log('사용자가 결제를 취소하였습니다.');
        } else if (error.code === 'INVALID_CARD_COMPANY') {
          console.log('유효하지 않은 카드입니다.');
        } else {
          alert('결제 요청 중 에러 발생! 관리자에게 문의해주세요.');
          console.error('결제 요청 중 에러 발생:', error);
        }
      }
    } catch (error) {
      console.error('토스페이먼츠 로드에 실패했습니다:', error);
    } finally {
      setIsProcessing(false); // 결제 완료 후 처리 상태 초기화
    }
  };

  const closeLoginModal = () => {
    setShowLoginModal(false);
  };

  if (loading) return null;

  return (
    <Container>
      <Helmet>
        <title>영정사진 | 장수사진 | Jangsu AI - 상세페이지</title>
        <meta name='description' content='장수사진, 영정사진을 AI 기술로 멋지게 만들어 드립니다.' />
        <meta name='keywords' content='영정사진, 장수사진, AI 영정사진, AI 장수사진' />
      </Helmet>
      <Header>
        <StyledLink to='/'>
          <IconWrapper>
            <IconImage src={BackIcon} alt='back_icon' />
          </IconWrapper>
        </StyledLink>
      </Header>
      <Content>
        <CrossImageWrapper>
          <CrossImage src={MainCardImg} alt='main_image' />
          <CrossImage src={MainCardImg2} alt='main_image' />
        </CrossImageWrapper>
        <InfoContainer>
          <Tags>
            <Tag>남녀 모두 가능</Tag>
            <Tag>60대 이상 추천</Tag>
          </Tags>
          <Title>영정사진 | 장수사진</Title>
          <SubTitle>영정사진 | 장수사진</SubTitle>
          <SubTitleH3>영정사진 | 장수사진</SubTitleH3>
          <PriceContainer>
            <Discount>65%</Discount>
            <Price>₩28,000</Price>
            <SubDescription>(사진 10장을 드려요)</SubDescription>
          </PriceContainer>
          <OriginalPrice>₩80,000</OriginalPrice>
        </InfoContainer>
        <TabContainer>
          <Tab>상세 설명</Tab>
        </TabContainer>
        <DescriptionContainer>
          <Description>
            <h2>✨ 최선을 다해 어르신의 아름다운 모습을 정성스럽게 담아드려요</h2>
            <h2>✅ 이런 분들께 필요해요</h2>
            <div> • 거동이 불편하셔서 사진관에 직접가서 촬영이 어려우신 분</div>
            <div> • 준비된 사진 없이 급하게 장례식에 영정사진으로 써야하시는 분</div>
            <div> • 장수/영정 사진의 금액이 부담되시는 분</div>
            <div> • 오랜 투병생활이나 사고로 아픈 모습을 담고 싶지 않으신 분</div>
          </Description>
          <ImageGallery>
            <Image src={ExamCardImg1} alt='gallery_image' />
            <Image src={ExamCardImg2} alt='gallery_image' />
          </ImageGallery>
          <Description>
            <h2>👉 서비스 소개</h2>
            <div>
              안녕하세요, 저희는 특별한 AI 기술로 장수사진과 영정사진을 제작해드리는 Jangsu
              AI입니다.
            </div>
            <div>
              이번 프로젝트는 특히 빠른 작업이 필요하거나, 사진 촬영을 위해 거동이 불편하시거나,현재
              모습을 촬영하기 어려운 분들을 위해준비했습니다.
            </div>
            <div>
              저희가 이 프로젝트를 시작하게 된 이유는 제 개인적인 경험에서 비롯되었습니다. 평소 많이
              의지하고 따르던 외할머니께서 갑자기 돌아가셨을 때, 부모님께서는 슬픔에 정신이
              없으셨고, 갑작스럽 게 영정사진을 준비해야 했던 일이 큰 어려움으로 다가왔습니다. 이
              경험을 통해, 저희 부모님과 같은 상황에 놓인 분들에게 꼭 필요한 작업이라 느꼈고, 그래서
              저희가 이 프로젝트를 시작하게 되었습니다.
            </div>
            <div>
              가족의 기억을 더욱 아름답게 담아드리고, 여러분의 소중한 추억을 위해 정성을
              다하겠습니다.
            </div>
            <h2>🌟 진행 과정 3STEP</h2>
            <h4>주문 👉 고객님의 사진 제출 👉 고화질 원본 작업물 발송</h4>
            <h2>🙋‍♀️🙋‍♂️ 자주하시는 문의</h2>
            <div> • 흑백사진도 괜찮나요? 네! 컬러 적용됩니다.</div>
            <div>
              • 고화질로 복원할 수 있나요? 그럼요! 이목구비가 보이는 사진을 저화질이더라도
              보내주시면 고화질 작업 가능합니다.
            </div>
            <h2>📍 3가지 옵션</h2>
            <div>
              • 스탠다드: 가장 빠른 작업으로, 작업한 결과물 중 잘나온 10장의 사진을 선정하여
              제공해드려요.
            </div>
            <div>
              • 디럭스: 고객님께서 원하는 스타일을 직접 선택하신 후, 그에 맞춰 정성스럽게 디테일을
              더한 사진을 제작해립니다.
            </div>
            <div>
              • 프리미엄: 디럭스 작업 결과물에 고급 액자를 포함하여 하루 안에 빠르게
              퀵배송해드립니다.
            </div>
            <h2>💡 좋은 결과를 만들기 위한 팁이에요</h2>
            <div>
              1. 실제 사진과 같은 품질의 결과를 얻기 위해선 보정 되지 않은 사진을 사용해야해요.
            </div>
            <div>2. 같은 나이대의 사진을 올려주어야 해요.</div>
            <div>3. 비슷한 시기에 찍은 사진이라면 결과물도 일관되게 나와요.</div>
            <div>4. 얼굴이 크게 나온 사진일수록 좋아요.</div>
            <div>5. 웃고 있지 않은 정면 사진 2장 정도 포함되면 좀 더 좋은 결과가 나와요.</div>
            <div>6. 치아가 나오도록 환하게 웃는 사진은 치아가 그대로 나올 수 있어요.</div>
            <div>
              7. 얼굴에 그림자 나온 사진은 결과에서도 그림자가 나올 수 있어요. 그림자는 피해주세요.
            </div>
            <h2>🚨 모든 결과가 항상 좋을 수는 없어요</h2>
            <div>
              저희 서비스는 계속해서 발전하고 있어요. AI가 생성하는 결과가 항상 만족스러울 수는
              없어요. 만족스럽지 못한 결과가 발생할수도 있음을 이해해 주시기 바래요. 계속 진행을
              하시면, 이러한 결과에 동의 하는 것으로 간주돼요.
            </div>
            <h2>🚫 개인정보 보호</h2>
            <div>
              타인의 얼굴 사진은 개인 정보로 간주되며 저작권의 보호를 받아요. 동의 없이 사용하거나
              공유하면 법적 결과를 초래할 수 있으며, 저작권 침해로 인한 모든 손해에 대해 사용자는
              개인적으로 책임을 지게돼요. 모든 사용자는 타인의 프라이버시와 권리를 존중해야 해요.
            </div>
          </Description>
          <IframeStyle
            src='https://ads-partners.coupang.com/widgets.html?id=817249&template=carousel&trackingCode=AF6003920&subId=&width=680&height=140&tsource='
            frameborder='0'
            scrolling='no'
            referrerpolicy='unsafe-url'
            browsingtopics
          />
          <div>※ 쿠팡 파트너스 활동을 통해 일정액의 수수료를 제공받을 수 있습니다.</div>
        </DescriptionContainer>
      </Content>
      {!showLoginModal && (
        <PurchaseButtonWrapper>
          <PurchaseButton onClick={handlePayment} disabled={isProcessing}>
            결제하기
          </PurchaseButton>
        </PurchaseButtonWrapper>
      )}
      {showLoginModal && (
        <Modal>
          <LoginModal show={showLoginModal} onClose={closeLoginModal} />
        </Modal>
      )}
    </Container>
  );
};

export default Detail;

const Modal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100vh;
  background-color: #fff;
  overflow: hidden;
`;

const Header = styled.header`
  width: 100%;
  height: 55px;
  display: flex;
  align-items: center;
  padding: 5px;
  background-color: white;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

const StyledLink = styled(Link)`
  display: flex;
  align-items: center;
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
`;

const IconImage = styled.img`
  width: 24px;
  height: 24px;
`;

const Content = styled.div`
  flex: 1;
  overflow-y: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;

  &:focus {
    scrollbar-width: thin;
    -ms-overflow-style: auto;
  }

  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;

const CrossImageWrapper = styled.div`
  position: relative;
  width: 335px;
  height: 400px;
  left: 50%;
  transform: translateX(-50%);
  margin: 2vw 0;
`;

const CrossImage = styled.img`
  position: absolute;
  width: 100%;
  height: auto;
  opacity: 0;
  animation: fadeInOut 8s infinite;
  loading: lazy;

  &:nth-of-type(1) {
    animation-delay: 0s;
  }

  &:nth-of-type(2) {
    animation-delay: 4s; /* 두 번째 이미지가 첫 번째 이미지 이후에 시작 */
  }

  @keyframes fadeInOut {
    0% {
      opacity: 0;
    }
    25% {
      opacity: 1;
    }
    50% {
      opacity: 1;
    }
    75% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
`;

const InfoContainer = styled.div`
  padding-top: 20px;
  padding-right: 16px;
  padding-left: 16px;
`;

const Tags = styled.div`
  display: flex;
  gap: 8px;
  margin-bottom: 8px;
`;

const Tag = styled.span`
  background-color: #bdfbb3bd;
  color: #159d00;
  padding: 4px 8px;
  border-radius: 5px;
  font-size: 12px;
  font-weight: bold;
`;

const Title = styled.h1`
  font-size: 28px;
  font-weight: bold;
  margin: 0;
  margin-bottom: 8px;
`;

const SubTitle = styled.h2`
  display: none;
`;

const SubTitleH3 = styled.h3`
  display: none;
`;

const PriceContainer = styled.div`
  display: flex;
  align-items: baseline;
  margin-bottom: 8px;
`;

const Discount = styled.span`
  font-size: 16px;
  color: red;
  margin-right: 8px;
`;

const Price = styled.span`
  font-size: 20px;
  font-weight: bold;
  margin-right: 8px;
`;

const SubDescription = styled.span`
  font-size: 14px;
  font-weight: 500;
  color: #7b7979;
`;

const OriginalPrice = styled.span`
  font-size: 16px;
  color: #888;
  text-decoration: line-through;
`;

const DescriptionContainer = styled.div`
  padding: 0 4%;
  padding-bottom: 80px;
`;

const Description = styled.div`
  font-size: 14px;
  color: #555;
  h2 {
    color: #000000;
  }
`;

const TabContainer = styled.div`
  display: flex;
  border-bottom: 1px solid #ddd;
  margin-bottom: 16px;
`;

const Tab = styled.div`
  padding: 10px 16px;
  cursor: pointer;
  flex: 1;
  text-align: center;
  border-bottom: ${(props) => (props.active ? '2px solid black' : 'none')};
  font-weight: ${(props) => (props.active ? 'bold' : 'normal')};
`;

const ImageGallery = styled.div`
  /* display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 8px; */
  margin-bottom: 16px;
`;

const Image = styled.img`
  width: 100%;
  height: auto;
  /* border-radius: 8px; */
`;

const IframeStyle = styled.iframe`
  width: 100%;
  height: auto;
`;

const PurchaseButtonWrapper = styled.footer`
  position: fixed;
  bottom: 0.1vh;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  height: 74px;
  padding: 10px;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const PurchaseButton = styled.button`
  width: 95%;
  padding: 15px;
  font-size: 16px;
  background-color: #000;
  color: white;
  border: none;
  border-radius: 15px;
  cursor: pointer;
  &:hover,
  &:active {
    opacity: 0.8;
  }
`;
// const PurchaseButtonWrapper = styled.footer`
//   display: flex;
//   justify-content: center;
//   width: 100%;
//   padding: 4px 1px;
//   background: transparent !important;
//   z-index: 1001;
// `;

// const PurchaseButton = styled.button`
//   width: 95%;
//   padding: 15px;
//   font-size: 16px;
//   background-color: #000;
//   color: white;
//   border: none;
//   border-radius: 15px;
//   cursor: pointer;
//   &:hover,
//   &:active {
//     opacity: 0.8;
//   }
// `;
