import React from 'react';
import styled from '@emotion/styled';
import { useAuth } from '../contexts/AuthContext';
import Modal from './Modal';
import { LoginButton } from './Button';
// import { useNavigate } from 'react-router-dom';

const LoginModal = ({ show, onClose }) => {
  const { login } = useAuth();
  // const navigate = useNavigate();

  const handleLogin = (loginType) => {
    if (loginType === 'google') {
      login('google');
    } else if (loginType === 'kakao') {
      const kakaoAuthUrl = `https://kauth.kakao.com/oauth/authorize?client_id=${process.env.REACT_APP_KAKAO_REST_API_KEY}&redirect_uri=${process.env.REACT_APP_KAKAO_REDIRECT_URI}&response_type=code`;
      window.location.href = kakaoAuthUrl;
    }
    onClose();
    // navigate('/');
  };

  return (
    <Modal show={show} onClose={onClose}>
      <Container>
        <LogoContainer>
          <LogoText>Jangsu AI</LogoText>
        </LogoContainer>
        <LoginButtonWrapper>
          <LoginButton onClick={() => handleLogin('google')}>구글 로그인</LoginButton>
          <LoginButton
            onClick={() => handleLogin('kakao')}
            style={{ backgroundColor: '#FEE400', color: '#191701', marginBottom: '4vh' }}
          >
            카카오 로그인
          </LoginButton>
        </LoginButtonWrapper>
      </Container>
    </Modal>
  );
};

export default LoginModal;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  padding-top: 45px;
`;

const LogoText = styled.h1`
  font-size: 45px;
  font-weight: bold;
  margin: 0;
  white-space: nowrap;
`;

const LoginButtonWrapper = styled.div`
  width: 100%;
  padding-bottom: 25px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;
