import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled'; // 스타일링을 위한 라이브러리
import { useNavigate } from 'react-router-dom';
import { auth } from '../firebase/firebaseConfig';
import { signInWithCustomToken } from 'firebase/auth';

const KakaoCallback = () => {
  const navigate = useNavigate();
  const [loading] = useState(true); // 로딩 상태 관리

  useEffect(() => {
    const verifyLogin = async () => {
      const code = new URL(window.location.href).searchParams.get('code');

      if (code) {
        try {
          // Firebase Functions에 Kakao Authorization Code 전송
          const response = await fetch(process.env.REACT_APP_FIREBASE_FUNCTIONS_KAKAOLOGIN, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ code }),
          });

          const data = await response.json();

          // alert(data.firebaseToken)
          if (data.firebaseToken) {
            // Firebase Custom Token으로 로그인
            await signInWithCustomToken(auth, data.firebaseToken);
            navigate('/'); // 로그인 성공 시 리디렉션
          } else {
            console.error('Firebase 토큰이 없습니다.');
          }
        } catch (error) {
          console.error('카카오 로그인 요청 실패:', error);
          alert(error);
        }
      }
    };

    verifyLogin();
  }, [navigate]);

  return (
    <Container>
      {loading ? (
        <LoadingWrapper>
          <LoadingSpinner />
          <Message>
            로그인 처리 중입니다.
            <br />
            잠시만 기다려 주세요...
          </Message>
        </LoadingWrapper>
      ) : (
        <Message>로그인 실패! 다시 시도해 주세요.</Message>
      )}
    </Container>
  );
};

export default KakaoCallback;

// 로딩 스피너 스타일 정의
const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f0f0f0;
`;

const LoadingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const LoadingSpinner = styled.div`
  border: 8px solid #121212;
  border-top: 8px solid #f3f3f3;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: spin 2s linear infinite;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

const Message = styled.p`
  margin-top: 16px;
  font-size: 18px;
  color: #333;
  white-space: pre-wrap;
`;
