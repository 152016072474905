// src/ProtectedRoute.jsx
import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { useAuth } from './contexts/AuthContext';
import LoginModal from './components/LoginModal';
import { useNavigate } from 'react-router-dom';

const ProtectedRoute = ({ element }) => {
  const { user } = useAuth();
  const [showLoginModal, setShowLoginModal] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (!user) {
      setShowLoginModal(true);
    }
  }, [user]);

  if (user) {
    return element;
  }

  return (
    <>
      {element}
      {showLoginModal && (
        <Modal>
          <LoginModal
            show={showLoginModal}
            onClose={() => {
              setShowLoginModal(false);
              navigate('/');
            }}
          />
        </Modal>
      )}
    </>
  );
};

export default ProtectedRoute;

const Modal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1001; /* Ensure modal is behind footer */
`;
