import { initializeApp } from 'firebase/app';
import {
  getAuth,
  GoogleAuthProvider,
  signInWithPopup,
  signOut,
  deleteUser as firebaseDeleteUser,
} from 'firebase/auth';
import {
  getFirestore,
  collection,
  query,
  where,
  getDocs,
  deleteDoc,
  doc,
  setDoc,
} from 'firebase/firestore';
import { getStorage, ref, listAll, deleteObject } from 'firebase/storage';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

const app = initializeApp(firebaseConfig);

const auth = getAuth(app);
export const googleProvider = new GoogleAuthProvider();
export const db = getFirestore(app);
export const storage = getStorage(app);
// export const functions = getFunctions(app);

const saveUserInfo = async (user) => {
  if (!user) return;

  const userDoc = doc(db, 'users', user.uid);
  const userInfo = {
    uid: user.uid,
    email: user.email,
    displayName: user.displayName,
    photoURL: user.photoURL,
    createdAt: new Date(),
  };

  try {
    await setDoc(userDoc, userInfo, { merge: true });
    console.log('사용자 정보가 저장되었습니다.');
  } catch (error) {
    console.error('사용자 정보 저장 실패:', error);
  }
};

const signInWithGoogle = async () => {
  try {
    const result = await signInWithPopup(auth, googleProvider);
    const user = result.user;
    await saveUserInfo(user); // 사용자 정보 저장
    console.log('Google 로그인 성공:', user);
  } catch (error) {
    console.error('Google 로그인 실패:', error);
  }
};

const deleteAccount = async (user) => {
  if (user) {
    // Firestore에서 사용자 데이터 삭제
    await deleteDoc(doc(db, 'users', user.uid));
    // Firebase Authentication에서 사용자 삭제
    await firebaseDeleteUser(user);
  }
};

const deleteAllUserPhotos = async (userId) => {
  // Firestore에서 사진 데이터 삭제
  const photosQuery = query(collection(db, 'photos'), where('userId', '==', userId));
  const photosSnapshot = await getDocs(photosQuery);
  const deletePhotoPromises = photosSnapshot.docs.map((doc) => deleteDoc(doc.ref));
  await Promise.all(deletePhotoPromises);

  // Firebase Storage에서 사진 삭제
  const userPhotosRef = ref(storage, `photos/${userId}`);
  const listResult = await listAll(userPhotosRef);
  const deleteStoragePromises = listResult.items.map((itemRef) => deleteObject(itemRef));
  await Promise.all(deleteStoragePromises);
};

const uploadUserPhoto = async (downloadURL) => {
  const user = auth.currentUser;
  if (!user) {
    throw new Error('로그인이 필요합니다.');
  }
  if (user) {
    const photoData = {
      userId: user.uid,
      photoURL: downloadURL,
      uploadedAt: new Date(),
    };
    try {
      // await setDoc(doc(db, 'photos', user.uid), photoData);
      await setDoc(doc(db, 'photos', `${user.uid}_${Date.now()}`), photoData);
    } catch (error) {
      console.error('이미지 업로드에 실패했습니다:', error);
    }
  }
};

export { auth, signInWithGoogle, signOut, deleteAccount, deleteAllUserPhotos, uploadUserPhoto };
