// src/Footer.jsx
import React from 'react';
import styled from '@emotion/styled';
import { Link, useLocation } from 'react-router-dom';
import HomeIcon from '../assets/images/home-icon.svg';
import HomeIconActive from '../assets/images/home-icon-active.svg';

import './footer.css';
// import GalleryIcon from '../assets/images/gallery-icon.png';
// import GalleryIconActive from '../assets/images/gallery-icon-active.png';
// import { useAuth } from '../contexts/AuthContext';

const Footer = ({ onHomeClick, onAlbumClick }) => {
  // const { user } = useAuth();
  // const navigate = useNavigate();
  const location = useLocation();

  const isHome = location.pathname === '/';

  // const handleAlbumClick = (e) => {
  //   if (!user) {
  //     e.preventDefault();
  //     onAlbumClick();
  //     navigate('/album');
  //   }
  // };

  return (
    <FooterContainer>
      <FooterIcon>
        <Link to='/' onClick={onHomeClick}>
          <IconWrapper>
            <IconImage src={isHome ? HomeIcon : HomeIconActive} alt='Home' />
          </IconWrapper>
        </Link>
      </FooterIcon>
      {/* <FooterIcon>
        <Link to='/album' onClick={handleAlbumClick}>
          <IconWrapper>
            <IconImage src={isHome ? GalleryIcon : GalleryIconActive} alt='Gallery' />{' '}
          </IconWrapper>
        </Link>
      </FooterIcon> */}
    </FooterContainer>
  );
};

export default Footer;

const FooterContainer = styled.footer`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: space-around;
  /* width: 100%; */
  height: 55px; /* 푸터 높이 고정 */
  padding: 5px;
  border-top: 0.5px solid lightgray;
  background-color: white;
  z-index: 1000; /* Ensure modal is behind footer */
`;

const FooterIcon = styled.div`
  font-size: 25px;
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px; /* 클릭 영역 확장 */
`;

const IconImage = styled.img`
  width: 25px;
  height: 25px;
`;
