/** @jsxImportSource @emotion/react */
import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
import { getStorage, ref, listAll, getDownloadURL } from 'firebase/storage';
import { useAuth } from '../contexts/AuthContext';
import EmptyBoxIcon from '../assets/images/empty-box-icon.png';
import { CreateButton } from '../components/Button';

const Album = () => {
  const { user } = useAuth(); // 현재 로그인한 사용자 정보 가져오기
  const [photos, setPhotos] = useState([]);
  const [selectedPhoto, setSelectedPhoto] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(true); // 로딩 상태 추가
  const storage = getStorage();

  useEffect(() => {
    const fetchPhotos = async () => {
      if (user) {
        const folderRef = ref(storage, `photos/${user.email}/result`);
        const listResults = await listAll(folderRef);
        const photoURLs = await Promise.all(
          listResults.items.map(async (item) => {
            if (item.name !== 'placeholder.txt') {
              const url = await getDownloadURL(item);
              return { id: item.name, url };
            }
            return null;
          })
        );
        setPhotos(photoURLs.filter((photo) => photo !== null));
        setLoading(false); // 로딩 상태 업데이트
      }
    };

    fetchPhotos();
  }, [user, storage]);

  const handlePhotoClick = (photo) => {
    setSelectedPhoto(photo);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedPhoto(null);
  };

  // const handleDownload = (photo) => {
  //   const link = document.createElement('a');
  //   link.href = photo.url;
  //   link.download = photo.id;
  //   document.body.appendChild(link);
  //   link.click();
  //   document.body.removeChild(link);
  // };

  if (loading) {
    return <LoadingContainer>로딩 중...</LoadingContainer>; // 로딩 중 화면
  }

  return (
    <Container>
      <Content>
        {photos.length > 0 ? (
          <PhotosContainer>
            <Title>{`< 결과물 >`}</Title>
            <Description>아래의 사진을 클릭해서 다운로드해주세요.</Description>
            <PhotosContainer>
              {photos.map((photo) => (
                <Photo key={photo.id} onClick={() => handlePhotoClick(photo)}>
                  <PhotoImage src={photo.url} alt='photo' />
                </Photo>
              ))}
            </PhotosContainer>
          </PhotosContainer>
        ) : (
          <MessageContainer>
            <Icon src={EmptyBoxIcon} alt='Empty Box' />
            <Title>앨범이 비어 있습니다</Title>
            <Description>아직 생성된 사진이 없습니다. 처음 사진을 만들어보세요!</Description>
            <StyledLink to='/detail'>
              <CreateButton style={{ width: '40%' }}>생성하기</CreateButton>
            </StyledLink>
          </MessageContainer>
        )}
      </Content>

      {showModal && selectedPhoto && (
        <Modal>
          <ModalContent>
            <CloseButton onClick={handleCloseModal}>x</CloseButton>
            <ModalImage src={selectedPhoto.url} alt='selected_photo' />
            <Description>{`<이미지 다운로드>`}</Description>
            <Description>모바일 : 이미지를 꾹 누르기</Description>
            <Description>PC : 마우스 왼쪽 버튼 클릭</Description>
            {/* <DownloadButton onClick={() => handleDownload(selectedPhoto)}>
              다운로드
            </DownloadButton> */}
          </ModalContent>
        </Modal>
      )}
    </Container>
  );
};

export default Album;

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  font-size: 24px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 85vh;
  background-color: #fff;
  overflow: hidden;
`;

const StyledLink = styled(Link)`
  text-decoration: none;
`;

const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  position: relative;
  flex-wrap: wrap;
`;

const PhotosContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
`;

const Photo = styled.div`
  width: 150px;
  height: 150px;
  overflow: hidden;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  cursor: pointer;
`;

const PhotoImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const MessageContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
`;

const Icon = styled.img`
  width: 100px;
  height: 100px;
  margin-bottom: 20px;
`;

const Title = styled.h1`
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px;
`;

const Description = styled.p`
  font-size: 14px;
  color: #555;
  margin-bottom: 20px;
`;

const Modal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ModalContent = styled.div`
  position: relative;
  background: white;
  padding: 20px;
  border-radius: 10px;
  max-width: 90%;
  max-height: 80%;
  overflow: auto;
  text-align: center;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background: #ff4d4d;
  color: white;
  border: none;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  cursor: pointer;
  font-size: 16px;
`;

const ModalImage = styled.img`
  max-width: 100%;
  max-height: 60vh;
  border-radius: 10px;
`;

// const DownloadButton = styled.button`
//   display: inline-block;
//   margin-top: 20px;
//   padding: 10px 20px;
//   background: #000;
//   color: #fff;
//   border: none;
//   border-radius: 5px;
//   text-decoration: none;
//   cursor: pointer;
//   &:hover {
//     opacity: 0.8;
//   }
// `;
