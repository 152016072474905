import styled from '@emotion/styled';

const Button = styled.button`
  width: 87%;
  max-width: 500px;
  padding: 5% 0;
  font-size: 95%;
  background-color: black;
  color: white;
  font-weight: 600;
  border: none;
  border-radius: 14px;
  cursor: pointer;
  transition: background-color 0.3s, opacity 0.3s;

  &:hover {
    opacity: 0.9;
    background-color: black;
  }

  &:active {
    opacity: 0.7;
    background-color: black;
  }
`;

// Button 스타일을 각 컴포넌트에 추가
const LoginButton = styled(Button)``;
const CreateButton = styled(Button)``;
const PurchaseButton = styled(Button)``;
const ContactButton = styled(Button)``;

export { LoginButton, CreateButton, PurchaseButton, ContactButton };
