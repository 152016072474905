/** @jsxImportSource @emotion/react */
import React from 'react';
import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
import FailIcon from '../assets/images/fail.png'; // 실패 아이콘 이미지 임포트

const Fail = () => {
  return (
    <Container>
      <IconWrapper>
        <IconImage src={FailIcon} alt='fail_icon' />
      </IconWrapper>
      <Title>결제가 실패하였습니다.</Title>
      <Description>
        죄송합니다.
        <br />
        결제 처리 중 문제가 발생했습니다.
        <br />
        다시 시도해 주세요.
      </Description>
      <ButtonWrapper>
        <StyledLink to='/'>
          <HomeButton>홈으로 돌아가기</HomeButton>
        </StyledLink>
        <StyledLink to='/contact'>
          <ContactButton>문의하기</ContactButton>
        </StyledLink>
      </ButtonWrapper>
    </Container>
  );
};

export default Fail;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #fff;
  padding: 16px;
  text-align: center;
`;

const IconWrapper = styled.div`
  margin-bottom: 20px;
`;

const IconImage = styled.img`
  width: 40px;
  height: 40px;
`;

const Title = styled.h1`
  font-size: 24px;
  font-weight: bold;
  margin: 16px 0;
`;

const Description = styled.p`
  font-size: 16px;
  color: #555;
  margin-bottom: 40px;
`;

const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 16px; /* 버튼 사이의 간격을 조정 */
`;

const StyledLink = styled(Link)`
  text-decoration: none;
`;

const HomeButton = styled.button`
  padding: 10px 20px;
  font-size: 16px;
  background-color: #000;
  color: #fff;
  border: none;
  border-radius: 11px;
  cursor: pointer;
  &:hover,
  &:active {
    opacity: 0.8;
  }
`;

const ContactButton = styled(HomeButton)`
  background-color: #ff4d4d; /* 문의하기 버튼 색상을 다르게 설정 */
`;
