import React from 'react';
import styled from '@emotion/styled';
import { keyframes } from '@emotion/react';

const Modal = ({ show, onClose, children }) => {
  if (!show) {
    return null;
  }

  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  return (
    <Overlay onClick={handleOverlayClick}>
      <ModalContent onClick={(e) => e.stopPropagation()}>
        <Handle />
        {children}
      </ModalContent>
    </Overlay>
  );
};

export default Modal;

const slideUp = keyframes`
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
`;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  /* bottom: 56px; */
  display: flex;
  justify-content: center;
  align-items: flex-end;
  z-index: 1001; /* Ensure modal is behind footer */
`;

const ModalContent = styled.div`
  background: white;
  border-radius: 15px 15px 0 0;
  width: 100%;
  max-width: 500px;
  height: 35vh;
  animation: ${slideUp} 0.3s ease-out;
  z-index: 1001; /* Ensure modal content is above the overlay */
  pointer-events: auto; /* Allow clicks on the modal content */
  position: relative;
`;

const Handle = styled.div`
  width: 30px;
  height: 4px;
  background: black;
  border-radius: 2px;
  position: absolute;
  top: 8px;
  left: 50%;
  transform: translateX(-50%);
`;
