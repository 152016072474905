// src/components/Popup.jsx
import React from 'react';
import styled from '@emotion/styled';

const Popup = ({
  show,
  onClose,
  title,
  message,
  secondMessage,
  onConfirm,
  confirmMessage,
  anotherMessage,
}) => {
  if (!show) {
    return null;
  }

  return (
    <Overlay onClick={onClose}>
      <PopupContent onClick={(e) => e.stopPropagation()}>
        <PopupTitle>{title}</PopupTitle>
        <PopupMessage>
          {message}
          <br />
          {secondMessage}
        </PopupMessage>
        <ButtonGroup>
          <CancelButton onClick={onClose}>{anotherMessage || '닫기'}</CancelButton>
          {confirmMessage ? (
            <ConfirmButton onClick={onConfirm}>{confirmMessage}</ConfirmButton>
          ) : null}
        </ButtonGroup>
      </PopupContent>
    </Overlay>
  );
};

export default Popup;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const PopupContent = styled.div`
  background: white;
  padding: 20px;
  border-radius: 8px;
  width: 90%;
  max-width: 400px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

const PopupTitle = styled.h2`
  margin-bottom: 10px;
`;

const PopupMessage = styled.p`
  margin-bottom: 20px;
`;

const ButtonGroup = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const CancelButton = styled.button`
  background: #ccc;
  color: #000;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-right: 10px;

  &:hover {
    opacity: 0.8;
  }
`;

const ConfirmButton = styled.button`
  background: #ff4d4d;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
`;
