// src/App.jsx
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import Home from './Home';
import Success from './payments/Success';
import Fail from './payments/Fail';
import Album from './Album';
import LoginModal from './components/LoginModal';
import Footer from './components/Footer';
import { AuthProvider, useAuth } from './contexts/AuthContext';
import Header from './components/Header';
import ProtectedRoute from './ProtectedRoute';
import './App.css';
import Settings from './Settings';
import Detail from './Detail';
import FAQ from './pages/FAQ';
import SubmitInfo from './pages/SubmitInfo';
import SubmitPhoto from './pages/SubmitPhoto';
import { HelmetProvider } from 'react-helmet-async';
import KakaoCallback from './components/KakaoCallback';

const AppContent = () => {
  const [showLoginModal, setShowLoginModal] = useState(false);
  const { user, loading } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();

  const hideHeaderFooter = ['/detail', '/settings', '/faq', '/contact'].includes(location.pathname);

  useEffect(() => {
    if (user) setShowLoginModal(false);
  }, [user]);

  const handleAlbumClick = () => {
    if (!user) setShowLoginModal(true);
  };

  const closeLoginModal = () => {
    setShowLoginModal(false);
    navigate('/');
  };

  const showLoginModalHandler = () => setShowLoginModal(true);

  if (loading) return null;

  return (
    <>
      <div className={showLoginModal ? 'no-shadow' : ''}>
        {!hideHeaderFooter && <Header className='header' onLogoClick={closeLoginModal} />}
        <div
          className={`content ${showLoginModal ? 'overlay' : ''} ${
            hideHeaderFooter ? 'no-padding-top no-scroll' : ''
          }`}
        >
          <Routes>
            <Route path='/' element={<Home />} />
            <Route path='/detail' element={<Detail />} />
            <Route path='/album' element={<ProtectedRoute element={<Album />} />} />
            <Route path='/success' element={<ProtectedRoute element={<Success />} />} />
            <Route path='/fail' element={<ProtectedRoute element={<Fail />} />} />
            <Route path='/submitinfo' element={<ProtectedRoute element={<SubmitInfo />} />} />
            <Route path='/submitphoto' element={<ProtectedRoute element={<SubmitPhoto />} />} />
            <Route
              path='/settings'
              element={<Settings onShowLoginModal={showLoginModalHandler} />}
            />
            <Route path='/faq' element={<FAQ />} />
            {/* <Route path='/contact' element={<Contact />} /> */}
            <Route path='/kakao/callback' element={<KakaoCallback />} />
          </Routes>
        </div>
        {!hideHeaderFooter && (
          <Footer
            className='footer'
            onHomeClick={closeLoginModal}
            onAlbumClick={handleAlbumClick}
          />
        )}
      </div>
      {showLoginModal && <LoginModal show={showLoginModal} onClose={closeLoginModal} />}
    </>
  );
};

export default function App() {
  return (
    <HelmetProvider>
      <AuthProvider>
        <Router>
          <AppContent />
        </Router>
      </AuthProvider>
    </HelmetProvider>
  );
}
