/** @jsxImportSource @emotion/react */
import React from 'react';
import styled from '@emotion/styled';
import { Link } from 'react-router-dom';

const SubmitInfo = () => {
  return (
    <Container>
      <Title>사진 제출 전 유의사항</Title>
      <InfoList>
        <InfoItem>사진은 선명하고 얼굴이 잘 보이도록 촬영해 주세요.</InfoItem>
        <InfoItem>배경은 깔끔한 단색을 권장합니다.</InfoItem>
        <InfoItem>파일 형식은 JPG, PNG를 지원합니다.</InfoItem>
        <InfoItem>최대 파일 크기는 5MB입니다.</InfoItem>
      </InfoList>
      <ButtonWrapper>
        <StyledLink to='/submitphoto'>
          <ProceedButton>다음</ProceedButton>
        </StyledLink>
      </ButtonWrapper>
    </Container>
  );
};

export default SubmitInfo;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  background-color: #fff;
  min-height: 90vh;
  text-align: center;
`;

const Title = styled.h1`
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
`;

const InfoList = styled.ul`
  list-style: none;
  padding: 0;
  margin-bottom: 30px;
`;

const InfoItem = styled.li`
  font-size: 16px;
  margin-bottom: 10px;
`;

const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const StyledLink = styled(Link)`
  text-decoration: none;
`;

const ProceedButton = styled.button`
  padding: 10px 20px;
  font-size: 16px;
  background-color: #000;
  color: #fff;
  border: none;
  border-radius: 11px;
  cursor: pointer;
  &:hover,
  &:active {
    opacity: 0.8;
  }
`;
