/** @jsxImportSource @emotion/react */
import { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import { Link, useNavigate } from 'react-router-dom';
import BackIcon from '../assets/images/back-icon.png';
import ForwardIcon from '../assets/images/forward-icon.png';
import { auth, deleteAccount } from '../firebase/firebaseConfig';
import Popup from '../components/Popup';
import { useAuth } from '../contexts/AuthContext';

const Settings = ({ onShowLoginModal }) => {
  const { logout } = useAuth();
  // const [showDeleteAllPhotosModal, setShowDeleteAllPhotosModal] = useState(false);
  const [showLogoutModal, setShowLogoutModal] = useState(false);
  const [showDeleteAccountModal, setShowDeleteAccountModal] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setIsLoggedIn(!!user);
    });
    return () => unsubscribe();
  }, []);

  // const handleDeleteAllPhotos = async () => {
  //   const user = auth.currentUser;
  //   if (user) {
  //     await deleteAllUserPhotos(user.uid);
  //     setShowDeleteAllPhotosModal(false);
  //     console.log('모든 사진 삭제됨');
  //   }
  // };

  const handleLogout = async () => {
    logout();
    setShowLogoutModal(false);
    console.log('로그아웃됨');
    navigate('/');
  };

  const handleDeleteAccount = async () => {
    const user = auth.currentUser;
    if (user) {
      await deleteAccount(user);
      setShowDeleteAccountModal(false);
      console.log('계정 삭제됨');
      navigate('/');
    }
  };

  return (
    <Container>
      <Header>
        <StyledLink to='/'>
          <IconWrapper>
            <IconImage src={BackIcon} alt='back_icon' />
          </IconWrapper>
        </StyledLink>
      </Header>
      <Content>
        <Title>설정</Title>
        <List>
          <StyledLink to='/faq'>
            <ListItem>
              자주 묻는 질문 <ArrowIcon src={ForwardIcon} alt='forward_icon' />
            </ListItem>
          </StyledLink>
          {/* <StyledLink to='/contact'>
            <ListItem>
              문의하기 <ArrowIcon src={ForwardIcon} alt='forward_icon' />
            </ListItem>
          </StyledLink> */}
          {isLoggedIn ? (
            <>
              {/* <ListItem onClick={() => setShowDeleteAllPhotosModal(true)}>
                <ListItemText>모든 사진 삭제하기 </ListItemText>
                <ArrowIcon src={ForwardIcon} alt='forward_icon' />
              </ListItem> */}
              <ListItem onClick={() => setShowLogoutModal(true)}>
                <ListItemText>로그아웃 하기 </ListItemText>
                <ArrowIcon src={ForwardIcon} alt='forward_icon' />
              </ListItem>
              <ListItem onClick={() => setShowDeleteAccountModal(true)}>
                <ListItemText style={{ color: 'darkgray' }}>계정 삭제하기 </ListItemText>
                <ArrowIcon src={ForwardIcon} alt='forward_icon' />
              </ListItem>
            </>
          ) : (
            <ListItem onClick={onShowLoginModal}>
              <ListItemText>로그인 하기</ListItemText>
              <ArrowIcon src={ForwardIcon} alt='forward_icon' />
            </ListItem>
          )}
        </List>
      </Content>
      {/* <Popup
        show={showDeleteAllPhotosModal}
        onClose={() => setShowDeleteAllPhotosModal(false)}
        title='모든 사진 삭제하기'
        message='정말로 삭제하시겠습니까?'
        confirmMessage='Delete'
        onConfirm={handleDeleteAllPhotos}
      /> */}
      <Popup
        show={showLogoutModal}
        onClose={() => setShowLogoutModal(false)}
        title='로그아웃'
        message='정말로 로그아웃 하시겠습니까?'
        confirmMessage='Logout'
        onConfirm={handleLogout}
      />
      <Popup
        show={showDeleteAccountModal}
        onClose={() => setShowDeleteAccountModal(false)}
        title='계정 삭제'
        message='정말로 계정을 삭제하시겠습니까?'
        confirmMessage='Delete'
        onConfirm={handleDeleteAccount}
      />
    </Container>
  );
};

export default Settings;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  background-color: #fff;
`;

const Header = styled.header`
  width: 100%;
  height: 55px;
  display: flex;
  align-items: center;
  padding: 5px;
  background-color: white;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

const StyledLink = styled(Link)`
  display: flex;
  align-items: center;
  text-decoration: none;
  color: inherit;
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px; /* 클릭 영역 확장 */
`;

const IconImage = styled.img`
  width: 24px;
  height: 24px;
`;

const Title = styled.h1`
  font-size: 36px;
  font-weight: bold;
  margin: 0 0 10px 0;
`;

const Content = styled.div`
  flex: 1;
  padding: 16px;
`;

const List = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`;

const ListItem = styled.li`
  display: flex;
  align-items: center; /* 수직 중앙 정렬 */
  justify-content: space-between; /* 양쪽 끝 정렬 */
  padding: 15px 0;
  border-bottom: 1px solid #ccc;
  cursor: pointer;
  width: 100%;
`;

const ListItemText = styled.span`
  flex: 1; /* 아이템 텍스트가 가능한 넓게 차지하도록 함 */
`;

const ArrowIcon = styled.img`
  width: 16px;
  height: 16px;
`;
